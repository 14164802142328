<template>
  <v-container fluid pa-0 class="bg-home">
    <v-container pa-0>
      <div class="hidden-md-and-down bg-header">
        <v-toolbar id="menu" height="70" color="transparent">
          <v-layout row wrap justify-center>
            <v-flex xs12 md12 mt-5>
              <v-toolbar-title class="w-100" style="text-align:center;">
                <h1 class="w-100 title-menu" style="margin-top:90px">
                  72 ล้านต้น พลิกฟื้นผืนป่า
                </h1>
                <br />
                <h2 class="w-100 title-sub-menu" style="margin-top:20px">
                  เฉลิมพระเกียรติพระบาทสมเด็จพระเจ้าอยู่หัว<br />
                  เนื่องในโอกาสพระราชพิธีมหามงคลเฉลิมพระชนมพรรษา 6 รอบ <br />28
                  กรกฎาคม 2567
                </h2>
              </v-toolbar-title>
            </v-flex>
            <v-flex xs12 md12 style="margin-top: -45px;">
              <div class="ta-c">
                <img
                  src="../assets/images/10578814.png"
                  alt=""
                  width="300"
                  height="110"
                />
              </div>
            </v-flex>
          </v-layout>
        </v-toolbar>

        <v-toolbar
          id="sub-menu"
          height="50"
          color="transparent"
          style="display: flex;justify-content: center;margin-top:51px;"
        >
          <v-container style="display: contents; ">
            <template>
              <v-list-tile
                v-for="item in items"
                :key="item.title"
                :to="item.path == '' ? '' : item.path"
                :exact="item.exact"
                active-class="secondary"
                class="listmenu"
                @click="gotoLink(item.path)"
              >
                <div>
                  <span class="jc-c font-sub-menu">{{ item.title }}</span>
                </div>
              </v-list-tile>
              <template class="" v-if="this.name === null || this.name === ''">
                <v-list-tile
                  v-for="item in items_login"
                  :key="item.title"
                  :to="item.path == '' ? '' : item.path"
                  :exact="item.exact"
                  active-class="secondary"
                  class="listmenu"
                  @click="gotoLink(item.path)"
                >
                  <div>
                    <span class="jc-c font-sub-menu">{{ item.title }}</span>
                  </div>
                </v-list-tile>
              </template>
              <template class="" v-if="this.name !== null && this.name !== ''">
                <v-list-tile active-class="secondary" class="listmenu">
                  <div>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark v-on="on" flat>
                          <span class="jc-c font-sub-menu">{{ name }}</span>
                          <v-icon>keyboard_arrow_down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-tile
                          v-for="(item, index) in listMenu"
                          :key="index"
                          @click="gotoLink(item.path)"
                        >
                          <v-list-tile-title
                            class="jc-sb"
                            style="display: flex;"
                            >{{ item.title
                            }}<v-icon>{{
                              item.icon
                            }}</v-icon></v-list-tile-title
                          >
                        </v-list-tile>
                      </v-list>
                    </v-menu>
                  </div>
                </v-list-tile>
              </template>
            </template>
          </v-container>
        </v-toolbar>
      </div>

      <div class="bg-header hidden-lg-and-up">
        <v-toolbar color="transparent" id="menu" height="114px">
          <v-toolbar-title class="title-menu-mobile">
            <v-toolbar-title class="w-100" style="text-align:center;">
              <span class="w-100 mt-3 title-menu-mobile"
                >72 ล้านต้น พลิกฟื้นผืนป่า</span
              ><br />
              <span class="w-100 mt-3 title-sub-menu-mobile"
                >เฉลิมพระเกียรติพระบาทสมเด็จพระเจ้าอยู่หัว<br />
                เนื่องในโอกาสพระราชพิธีมหามงคลเฉลิมพระชนมพรรษา 6 รอบ <br />28
                กรกฎาคม 2567</span
              >
            </v-toolbar-title>
          </v-toolbar-title>
        </v-toolbar>
        <div class="ta-c pl-3 pr-3">
          <v-divider></v-divider>
        </div>
        <v-toolbar color="transparent" height="56" id="sub-menu">
          <v-toolbar-items>
            <v-menu offset-y style="font-weight: 700;">
              <template v-slot:activator="{ on }">
                <v-btn color="secondary" dark v-on="on" flat>
                  <v-icon left dark>menu</v-icon>
                  เมนู
                </v-btn>
              </template>
              <v-list id="sub-menu">
                <v-list-tile
                  v-for="item in items"
                  :key="item.title"
                  :to="item.path == '' ? '' : item.path"
                  :exact="item.exact"
                  active-class="secondary"
                  class="listmenu"
                  @click="gotoLink(item.path)"
                >
                  <span class="jc-c font-sub-menu-mobile">{{
                    item.title
                  }}</span>
                </v-list-tile>
                <template v-if="this.name === null">
                  <v-list-tile
                    v-for="item in items_login"
                    :key="item.title"
                    :to="item.path == '' ? '' : item.path"
                    :exact="item.exact"
                    active-class="secondary"
                    class="listmenu"
                    @click="gotoLink(item.path)"
                  >
                    <span class="jc-c font-sub-menu-mobile">{{
                      item.title
                    }}</span>
                  </v-list-tile>
                </template>
                <template
                  class=""
                  v-if="this.name !== null && this.name !== ''"
                >
                  <v-list-tile
                    active-class="secondary"
                    class="listmenu"
                    :to="'/edit_profile' == '' ? '' : '/edit_profile'"
                    @click="gotoLink('/edit_profile')"
                  >
                    <v-list-tile-title class="jc-c font-sub-menu-mobile"
                      >แก้ไขข้อมูลส่วนตัว</v-list-tile-title
                    >
                  </v-list-tile>
                </template>
                <template
                  class=""
                  v-if="this.name !== null && this.name !== ''"
                >
                  <v-list-tile
                    active-class="secondary"
                    class="listmenu"
                    @click="gotoLink('/logout')"
                  >
                    <v-list-tile-title class="jc-c font-sub-menu-mobile"
                      >ออกจากระบบ</v-list-tile-title
                    >
                  </v-list-tile>
                </template>
              </v-list>
            </v-menu>
          </v-toolbar-items>
          <v-toolbar-items>
            <v-list-tile class="listmenu h-100" style="align-items:center">
              <v-list-tile-title
                class="jc-c listmenu font-sub-menu-mobile"
                v-if="this.name !== null && this.name !== ''"
                >{{ this.name }}</v-list-tile-title
              >
            </v-list-tile>
          </v-toolbar-items>
        </v-toolbar>
      </div>

      <v-content class="box-content" style="">
        <v-container pa-0 justify-center align-center flex fill-height>
          <v-layout justify-center align-center fill-height>
            <router-view @getUsername="getUsername" />
          </v-layout>
        </v-container>
      </v-content>
    </v-container>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "บันทึกการปลูกต้นไม้", path: "/seeding" },
        { title: "รายละเอียดโครงการ", path: "/project" },
        { title: "ขอรับกล้าไม้", path: "/howto" },
        { title: "ต้นไม้น่ารู้", path: "/knowledge" },
        {
          title: "สถิติการปลูกต้นไม้",
          path: "/statistic",
        },
      ],
      items_login: [{ title: "เข้าสู่ระบบ", path: "/login" }],
      name: "",
      emit_cardid: "",
      listMenu: [
        { title: "แก้ไขข้อมูลส่วนตัว", path: "/edit_profile", icon: "edit" },
        { title: "ออกจากระบบ", path: "/logout", icon: "logout" },
      ],
      fullName: "",
      isCompany: false,
      status: "",
    };
  },
  watch: {
    name() {
      this.name = this.name;
    },
  },
  created() {
    this.name = this.$cookies.get("name");
    if (this.$route.path.split("/")[1] === "logout") {
      this.gotoLink("/logout");
    }
  },
  methods: {
    getUsername(name_emit) {
      this.name = name_emit;
    },
    gotoLink(path) {
      // console.log(path);
      if (path === "/logout") {
        this.$cookies.remove("token", "/", process.env.VUE_APP_WEB);
        this.$cookies.remove("name", "/", process.env.VUE_APP_WEB);
        this.$cookies.remove("status", "/", process.env.VUE_APP_WEB);
        this.name = null;
        this.$router.push("/login");
      }
      // else if (path === "/static") {
      //   // console.log("test");
      //   window.open(
      //     "https://fp.forest.go.th/rfd_app/rfd_nursery/app/special_event_dashboard_2.php",
      //     "_blank"
      //   );
      // }
      else {
        this.$router.push(path);
      }
    },
  },
};
</script>
<style scoped>
.bg-home {
  background-image: url("../assets/images/bg-home3.jpg");
  /* background-size: cover;
  background-position: top; */
  background-size: inherit;
  background-position: bottom;
}

.box-content {
  height: calc(100vh - 171px);
  background: white;
  overflow: auto;
}

.title-menu {
  font-size: 32px;
  font-weight: 700;
  /* white-space: normal !important; */
  /* text-align: center; */
  line-height: 0;
  letter-spacing: 0 !important;
  color: #8d5a0c;
  text-shadow: 2px 1px 10px #ffffff;
}

.title-sub-menu {
  font-size: 21px;
  font-weight: 600;
  white-space: normal !important;
  text-align: center;
  line-height: 1;
  letter-spacing: 0 !important;
  color: #99500f;
  text-shadow: 2px 1px 10px #ffffff;
}

.title-menu-mobile {
  font-size: 20px;
  font-weight: 700;
  white-space: normal !important;
  text-align: center;
  line-height: 1.5;
  letter-spacing: -1px;
  color: #91601e;
  text-shadow: 2px 1px 10px #ffffff;
}

.title-sub-menu-mobile {
  font-size: 16px;
  font-weight: 700;
  white-space: normal !important;
  text-align: center;
  line-height: 1;
  letter-spacing: -0.2px;
  color: #b1843d;
  text-shadow: 2px 1px 10px #ffffff;
}

.font-sub-menu {
  font-size: 18px !important;
  font-weight: 700;
  white-space: nowrap;
}

.font-sub-menu-mobile {
  font-size: 16px !important;
  font-weight: 700;
}

.line-box {
  border-bottom: 1px solid black;
}

.bg-header {
  background-image: url("../assets/images/O928XX0.jpg");
  background-size: cover;
  background-position: center;
}

.listmenu {
  justify-content: center;
  display: flex;
  width: 100%;
}
</style>
